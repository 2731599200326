<div class="custom-lesson-container">
  <mat-toolbar color="primary">
    <span>Tworzenie niestandardowej lekcji</span>
  </mat-toolbar>
  <mat-dialog-content class="mat-typography">
    <div class="student-inputs">
      <mat-form-field appearance="outline">
        <mat-label>Typ ucznia</mat-label>
        <mat-select [(ngModel)]="lessonData.studentType" name="studentType">
          <mat-option *ngFor="let type of studentTypes" [value]="type.value">
            {{ type.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Powód</mat-label>
        <input
          matInput
          placeholder="Podaj powód"
          [(ngModel)]="lessonData.reason"
          name="reason"
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Poziom ucznia</mat-label>
        <mat-select [(ngModel)]="lessonData.studentLevel" name="studentLevel">
          <mat-option *ngFor="let level of studentLevels" [value]="level.value">
            {{ level.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Zainteresowania ucznia</mat-label>
        <input
          matInput
          placeholder="Podaj zainteresowania"
          [(ngModel)]="lessonData.studentInterest"
          name="studentInterest"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Temat lekcji</mat-label>
        <textarea
          matInput
          placeholder="Wpisz temat lekcji"
          [(ngModel)]="lessonData.plan"
          name="plan"
          rows="3"
        ></textarea>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Jak zaspokoimy potrzeby ucznia</mat-label>
        <textarea
          matInput
          placeholder="Opisz jak zaspokoimy potrzeby"
          [(ngModel)]="lessonData.needsApproach"
          name="needsApproach"
          rows="3"
        ></textarea>
      </mat-form-field>
    </div>

    <!-- Font Size, Padding, and Margin Controls -->
    <div class="controls">
      <h3>Adjust Styles</h3>
      <div>
        <span>Font Size:</span>
        <button (click)="increaseFontSize()">+</button>
        <button (click)="decreaseFontSize()">-</button>
      </div>
      <div>
        <span>Padding:</span>
        <button (click)="increasePadding()">+</button>
        <button (click)="decreasePadding()">-</button>
      </div>
      <div>
        <span>Margin:</span>
        <button (click)="increaseMargin()">+</button>
        <button (click)="decreaseMargin()">-</button>
      </div>
    </div>

    <mat-tab-group animationDuration="0ms">
      <mat-tab label="Gramatyka">
        <div class="grammar-container">
          <div class="html-input">
            <mat-form-field appearance="outline">
              <mat-label>Wpisz HTML</mat-label>
              <textarea
                matInput
                [(ngModel)]="htmlContent"
                name="htmlContent"
                rows="5"
              ></textarea>
            </mat-form-field>
            <div class="button-group">
              <button
                mat-raised-button
                color="accent"
                (click)="improveInputs()"
                [disabled]="
                  !lessonData.studentType ||
                  !lessonData.studentLevel ||
                  !lessonData.studentInterest
                "
              >
                Ulepsz dane
              </button>
              <button
                mat-raised-button
                color="primary"
                (click)="generateLesson()"
                [disabled]="
                  !lessonData.studentType ||
                  !lessonData.studentLevel ||
                  !lessonData.studentInterest
                "
              >
                Generuj lekcję
              </button>
            </div>
            <button
              mat-raised-button
              color="accent"
              (click)="generatePreviewFromData()"
            >
              Podgląd
            </button>
            <button
              mat-raised-button
              color="accent"
              (click)="printPreview()"
              [disabled]="!htmlContent"
            >
              Drukuj
            </button>
          </div>
          <div class="preview-container">
            <div
              #previewContainer
              class="a4-page"
              [innerHTML]="previewHtml"
            ></div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Słownictwo">
        <p>Content for Słownictwo tab goes here.</p>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button (click)="cancel()">Anuluj</button>
    <button mat-raised-button color="primary" (click)="saveLesson()">
      Zapisz lekcję
    </button>
  </mat-dialog-actions>
</div>
