import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { HttpClient } from '@angular/common/http';
import { SetsService } from './sets.service';
import { HelpersService } from './helpers.service';
import { RealTimeCommunicationService } from './real-time-communication.service';
import { VersionService } from './version.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class GroupsService {
  groups = [];
  url;
  private foundGroupsById = new Subject<any[]>();
  private groupsUpdated = new Subject<any[]>();
  private setAddedToGroup = new Subject<any[]>();
  private teachersGroupsUpdated = new Subject<any[]>();

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private setsService: SetsService,
    private helper: HelpersService,
    private rtcService: RealTimeCommunicationService,
    private versionService: VersionService,
    private spinner: NgxSpinnerService,
    private helpers: HelpersService,
  ) {
    const loc = window.location.hostname;
    this.url = this.versionService.url;
  }

  addGroupToList(newGroup, newGroupId) {
    newGroup.id = newGroupId;
    this.groups.unshift(newGroup);
    this.groupsUpdated.next(this.groups);
  }

  saveNewGroup(group): Observable<any> {
    return this.http.post(this.url + '/api/groups/addgroup', group);
    // .subscribe(response => {
    //
    //   this.getGroupsList()
    // })
  }

  getGroupsList() {
    this.http
      .get(this.url + '/api/groups/getgroupslist', {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        let groupsArray = Object.keys(response).map((i) => response[i]);

        groupsArray = groupsArray[0].map((incomingGroup) => {
          return {
            id: incomingGroup._id,
            name: incomingGroup.data.name,
            students: incomingGroup.data.students,
            level: incomingGroup.data.level,
            grade: incomingGroup.data.grade,
            teachers: incomingGroup.data.teachers,
            courseStartDate: incomingGroup.data.courseStartDate,
            courseEndDate: incomingGroup.data.courseEndDate,
            lessons: incomingGroup.data.lessons,
            course: incomingGroup.data.course,
            schedule: incomingGroup.data.schedule,
            syllabus: incomingGroup.data.syllabus,
            description: incomingGroup.data.description,
            cancellations: incomingGroup.data.cancellations,
            courseData: incomingGroup.data.courseData,
            substitutions: incomingGroup.data.substitutions,
            numberOfSpots: incomingGroup.data.numberOfSpots,
            makeUpClasses: incomingGroup.data.makeUpClasses,
            adult: incomingGroup.data.adult,
            individual: incomingGroup.data.individual,
            additionalData: incomingGroup.data.additionalData,
            sets: incomingGroup.data.sets,
          };
        });
        this.groups = groupsArray;

        this.groupsUpdated.next(this.groups);
      });
  }

  findGroupByName(name) {
    this.http
      .post(
        this.url + '/api/groups/findgroupbyname',
        { name: name },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        let groupsArray = Object.keys(response).map((i) => response[i]);

        groupsArray = groupsArray[0].map((incomingGroup) => {
          return {
            id: incomingGroup._id,
            name: incomingGroup.data.name,
            students: incomingGroup.data.students,
            level: incomingGroup.data.level,
            courseStartDate: incomingGroup.data.courseStartDate,
            courseEndDate: incomingGroup.data.courseEndDate,
            lessons: incomingGroup.data.lessons,
            grade: incomingGroup.data.grade,
            teachers: incomingGroup.data.teachers,
            course: incomingGroup.data.course,
            schedule: incomingGroup.data.schedule,
            syllabus: incomingGroup.data.syllabus,
            description: incomingGroup.data.description,
            cancellations: incomingGroup.data.cancellations,
            courseData: incomingGroup.data.courseData,

            substitutions: incomingGroup.data.substitutions,
            numberOfSpots: incomingGroup.data.numberOfSpots,
            makeUpClasses: incomingGroup.data.makeUpClasses,
            adult: incomingGroup.data.adult,
            individual: incomingGroup.data.individual,
            additionalData: incomingGroup.data.additionalData,
            sets: incomingGroup.data.sets,
          };
        });
        this.groups = groupsArray;

        this.groupsUpdated.next(this.groups);
      });
  }

  findGroupById(id) {
    this.http
      .get(this.url + '/api/groups/findgroupbyid/' + id, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        let groupsArray = Object.keys(response).map((i) => response[i]);

        groupsArray = groupsArray[0].map((incomingGroup) => {
          return {
            id: incomingGroup._id,
            name: incomingGroup.data.name,
            students: incomingGroup.data.students,
            level: incomingGroup.data.level,
            grade: incomingGroup.data.grade,
            teachers: incomingGroup.data.teachers,
            courseStartDate: incomingGroup.data.courseStartDate,
            courseEndDate: incomingGroup.data.courseEndDate,
            lessons: incomingGroup.data.lessons,
            course: incomingGroup.data.course,
            schedule: incomingGroup.data.schedule,
            syllabus: incomingGroup.data.syllabus,
            description: incomingGroup.data.description,
            cancellations: incomingGroup.data.cancellations,
            courseData: incomingGroup.data.courseData,

            substitutions: incomingGroup.data.substitutions,
            numberOfSpots: incomingGroup.data.numberOfSpots,
            makeUpClasses: incomingGroup.data.makeUpClasses,
            adult: incomingGroup.data.adult,
            individual: incomingGroup.data.individual,
            additionalData: incomingGroup.data.additionalData,
            sets: incomingGroup.data.sets,
          };
        });
        this.groups = groupsArray;
        console.log(
          '🚀 ~ file: groups.service.ts ~ line 147 ~ GroupsService ~ findGroupById ~ this.groups',
          this.groups,
        );

        this.foundGroupsById.next(this.groups[0]);
      });
  }

  findTeachersGroups(teacherId) {
    this.http
      .get(this.url + '/api/groups/findteachersgroups/' + teacherId, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        let groupsArray = Object.keys(response).map((i) => response[i]);

        groupsArray = groupsArray[0].map((incomingGroup) => {
          return {
            id: incomingGroup._id,
            name: incomingGroup.data.name,
            students: incomingGroup.data.students,
            level: incomingGroup.data.level,
            grade: incomingGroup.data.grade,
            courseStartDate: incomingGroup.data.courseStartDate,
            courseEndDate: incomingGroup.data.courseEndDate,
            lessons: incomingGroup.data.lessons,
            teachers: incomingGroup.data.teachers,
            course: incomingGroup.data.course,
            schedule: incomingGroup.data.schedule,
            syllabus: incomingGroup.data.syllabus,
            description: incomingGroup.data.description,
            cancellations: incomingGroup.data.cancellations,
            courseData: incomingGroup.data.courseData,

            substitutions: incomingGroup.data.substitutions,
            numberOfSpots: incomingGroup.data.numberOfSpots,
            makeUpClasses: incomingGroup.data.makeUpClasses,
            adult: incomingGroup.data.adult,
            individual: incomingGroup.data.individual,
            additionalData: incomingGroup.data.additionalData,
            sets: incomingGroup.data.sets,
          };
        });
        this.groups = groupsArray;
        this.groups.map((group) => {
          let teachersIds = [];
          group.teachers.forEach((teacher) => {
            teachersIds.push(teacher.id);
          });
          group.teacherIds = teachersIds;
        });
        console.log('this.groups: ', this.groups);

        this.teachersGroupsUpdated.next(this.groups);
      });
  }
  findGroups(teacherName?: string, dayOfWeek?: string) {
    const requestBody = {
      teacherName: teacherName,
      dayOfWeek: dayOfWeek, // This is optional, include it only if you want to filter by day
    };

    this.http
      .post(this.url + '/api/groups/find-groups', requestBody, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        let groupsArray = Object.keys(response).map((i) => response[i]);

        groupsArray = groupsArray[0].map((incomingGroup) => {
          return {
            id: incomingGroup._id,
            name: incomingGroup.data.name,
            students: incomingGroup.data.students,
            level: incomingGroup.data.level,
            grade: incomingGroup.data.grade,
            courseStartDate: incomingGroup.data.courseStartDate,
            courseEndDate: incomingGroup.data.courseEndDate,
            lessons: incomingGroup.data.lessons,
            teachers: incomingGroup.data.teachers,
            course: incomingGroup.data.course,
            schedule: incomingGroup.data.schedule,
            syllabus: incomingGroup.data.syllabus,
            description: incomingGroup.data.description,
            cancellations: incomingGroup.data.cancellations,
            courseData: incomingGroup.data.courseData,

            substitutions: incomingGroup.data.substitutions,
            numberOfSpots: incomingGroup.data.numberOfSpots,
            makeUpClasses: incomingGroup.data.makeUpClasses,
            adult: incomingGroup.data.adult,
            individual: incomingGroup.data.individual,
            additionalData: incomingGroup.data.additionalData,
            sets: incomingGroup.data.sets,
          };
        });
        this.groups = groupsArray;
        this.groups.map((group) => {
          let teachersIds = [];
          group.teachers.forEach((teacher) => {
            teachersIds.push(teacher.id);
          });
          group.teacherIds = teachersIds;
        });
        console.log('this.groups: ', this.groups);
        this.spinner.hide();
        this.groupsUpdated.next(this.groups);
      });
  }

  // filterStudents(text) {
  //
  //   const lowerText = text.toLowerCase()
  //   const filteredStudents = this.groups.filter(e => e.childFirstName.toLowerCase().includes(lowerText))

  //   this.groupsUpdated.next(filteredStudents)

  // }

  removeGroup(id) {
    this.http
      .delete(this.url + '/api/groups/removegroup/' + id, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        // this.groups = this.groups.filter((e) => e.id !== id);
        // this.groupsUpdated.next(this.groups);
      });
  }

  updateGroup(groupId, _group) {
    let group = this.helpers.clone(_group);
    delete group.sets;
    if (group.now) {
      group.now = null;
    }
    this.http
      .put(this.url + '/api/groups/updategroup2/' + groupId, group, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        console.log(
          '🚀 ~ file: groups.service.ts:262 ~ GroupsService ~ .subscribe ~ response',
          response,
        );
        // this.groups = this.groups.filter((e) => e.id !== id);
        // this.groupsUpdated.next(this.groups);
      });
  }

  addSetToGroup(groupId, set, params?, customDate?) {
    const setToAssign: any = {
      added: customDate ? customDate : Date.now(),
      parentSet: set.id,
      id: this.helper.makeid(),
      children: set.children,
      name: set.name,
      answersLang: set.answersLang,
      questionLang: set.questionLang,
      type: set.type,
      simpleWords: set.simpleWords,
      textChunks: set.data?.textChunks,
      dialogue: set.data?.dialogue,
      params: params,
      items: set.items.map((item) => {
        return {
          wordId: item.wordId,
          meaningId: item.meaningId,
          item: {},
          star: false,
          stats: {},
        };
      }),
    };
    this.http
      .put(this.url + '/api/groups/addset/' + groupId, setToAssign, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        // const NumberOfgroupToUpdate = this.groups.findIndex(
        //   (group) => group.id == groupId
        // );
        // console.log("NumberOfgroupToUpdate: ", NumberOfgroupToUpdate);
        // this.groups[NumberOfgroupToUpdate].sets.push(set);
        // this.foundGroupsById.next(this.groups[NumberOfgroupToUpdate]);
        // this.setsService.updateSetsListsGroup(
        //   this.groups[NumberOfgroupToUpdate]
        // );
        // this.setsService.updateSetsList();
        this.setAddedToGroup.next(setToAssign);
      });
  }
  updateGroupNew(groupId, group) {
    // this.http
    //   .post(this.url + '/api/groups/updategroupnew', group, {
    //     headers: { Authorization: `Bearer ${this.authService.getToken()}` },
    //   })
    //   .subscribe((response) => {
    //     console.log(
    //       '🚀 ~ file: groups.service.ts ~ line 304 ~ GroupsService ~ .subscribe ~ response',
    //       response,
    //     );
    // const NumberOfgroupToUpdate = this.groups.findIndex(
    //   (group) => group.id == groupId
    // );
    // console.log("NumberOfgroupToUpdate: ", NumberOfgroupToUpdate);
    // this.groups[NumberOfgroupToUpdate].sets.push(set);
    // this.foundGroupsById.next(this.groups[NumberOfgroupToUpdate]);
    // this.setsService.updateSetsListsGroup(
    //   this.groups[NumberOfgroupToUpdate]
    // );
    // this.setsService.updateSetsList();
    // });
  }

  removeSetFromGroup(groupId, set) {
    this.http
      .put(
        this.url + '/api/groups/removeset/' + groupId,
        { setToRemove: set },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => { });
  }

  removeSetFromStudentsInGroup(group, setToRemoveId) {
    console.log('setToRemoveId: ', setToRemoveId);
    group.students.forEach((student) => {
      this.http
        .put(
          this.url + '/api/customers/removesetfromstudent/' + student,
          { setId: setToRemoveId },
          {
            headers: { Authorization: `Bearer ${this.authService.getToken()}` },
          },
        )
        .subscribe((response) => { });
    });
  }

  removeSetFromStudent(studentId, setToRemoveId) {
    this.http
      .put(
        this.url + '/api/customers/removesetfromstudent/' + studentId,
        { setId: setToRemoveId },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => { });
  }
  setUsersAgeStatus(
    studentAppId,
    ageStatus: 'smallChild' | 'mediumChild' | 'largeChild',
  ) {
    this.http
      .put(
        this.url + '/api/users/setuseragestatus/' + studentAppId,
        { ageStatus: ageStatus },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => { });
  }

  removeCurrentSetFromStudent(studentId, setToRemoveId) {
    this.http
      .put(
        this.url + '/api/customers/removecurrentsetfromstudent/' + studentId,
        { setId: setToRemoveId },
        {
          headers: {
            Authorization: `Bearer ${this.authService.getToken()}`,
          },
        },
      )
      .subscribe((response) => { });
  }

  generateId2ForSets(studentId) {
    this.http
      .put(this.url + '/api/customers/generateid2forsets/' + studentId, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => { });
  }

  foundGroupsByIdListener() {
    return this.foundGroupsById.asObservable();
  }

  groupsUpdatedListener() {
    return this.groupsUpdated.asObservable();
  }
  teachersGroupsUpdatedListener() {
    return this.teachersGroupsUpdated.asObservable();
  }
  setAddedToGroupListener() {
    return this.setAddedToGroup.asObservable();
  }
}
