import { Component, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomLessonCreatorService } from './custom-lesson-creator.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import print from 'print-js';
import { ChatService } from 'src/app/shared/services/chat.service';
import { ChatbotsService } from 'src/app/shared/services/chatbots.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as showdown from 'showdown';

@Component({
  selector: 'app-custom-lesson-creator',
  templateUrl: './custom-lesson-creator.component.html',
  styleUrls: ['./custom-lesson-creator.component.css']
})
export class CustomLessonCreatorComponent {
  message = '';
  conversation: Array<{
    role: string;
    content: string;
    splitContent?: Array<{ type: 'text' | 'code'; value: any }>;
  }> = [];
  private converter: showdown.Converter;
  chatbots = [];
  selectedChatbot;
  mentorChatbot;
  files: File[] = [];
  private mentorResult: string = '';

  // Style control properties
  public fontSize: number = 16;
  public padding: number = 10;
  public margin: number = 10;

  @ViewChild('previewContainer') previewContainer!: ElementRef;
  @ViewChild('chatMessages') private chatMessages!: ElementRef;
  groupData: any;
  public htmlContent = '';
  public previewHtml: SafeHtml = '';
  public lessonData = {
    title: '',
    description: '',
    duration: 60,
    activities: [],
    reason: '',
    plan: '',
    studentLevel: '',
    studentInterest: '',
    learningStyle: '',
    needsApproach: '',
    studentType: ''
  };

  public studentTypes = [
    { value: 'elementary', label: 'Szkoła podstawowa' },
    { value: '8grade', label: 'Egzamin ósmoklasisty' },
    { value: 'highschool', label: 'Szkoła średnia' },
    { value: 'matura', label: 'Maturzysta' },
    { value: 'adult', label: 'Dorosły' }
  ];

  public studentLevels = [
    { value: 'Skup się tylko na bardzo podstawowych informacjach, nie podawaj wyjątkow', label: 'Bardzo duże zaległości i problemy' },
    { value: 'Poszczegolne aspekty wprowadzaj w malych porcjach i podawaj duzo przykladow', label: 'Trudności w opanowaniu nowych zagadnień' },
    { value: '', label: 'Średnie tempo nauki - bez większych problemów, ale też bez szczególnych osiągnięć' },
    { value: 'omow tez mniej oczywiste aspekty zagadnienia', label: 'Szybkie przyswajanie wiedzy' },
    { value: 'omow tez bardziej zaawansowane aspekty zagadnienia i wyjatki', label: 'Sprawnie i z łatwością opanowuje nowe zagadnienia i wykracza poza swój poziom' }
  ];

  constructor(
    private dialogRef: MatDialogRef<CustomLessonCreatorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private customLessonService: CustomLessonCreatorService,
    private sanitizer: DomSanitizer,
    private chatService: ChatService,
    private chatbotsService: ChatbotsService,
    private snackBar: MatSnackBar
  ) {
    this.converter = new showdown.Converter();
    this.chatbots = this.chatbotsService.chatbots;
    this.selectedChatbot = this.chatbots.find(chatbot => chatbot.name === 'Daniel');
    this.mentorChatbot = this.chatbots.find(chatbot => chatbot.name === 'Mentor');
    if (!this.selectedChatbot || !this.mentorChatbot) {
      console.error('Required chatbots not found');
      this.snackBar.open('Error initializing chatbots', 'Close', { duration: 5000 });
    }
    this.groupData = data.group;
  }

  // Style control methods
  public increaseFontSize(): void {
    this.fontSize += 1;
    this.updateStyles('font-size');
  }

  public decreaseFontSize(): void {
    if (this.fontSize > 1) {
      this.fontSize -= 1;
      this.updateStyles('font-size');
    }
  }

  public increasePadding(): void {
    this.padding += 1;
    this.updateStyles('padding');
  }

  public decreasePadding(): void {
    if (this.padding > 0) {
      this.padding -= 1;
      this.updateStyles('padding');
    }
  }

  public increaseMargin(): void {
    this.margin += 1;
    this.updateStyles('margin');
  }

  public decreaseMargin(): void {
    if (this.margin > 0) {
      this.margin -= 1;
      this.updateStyles('margin');
    }
  }

  private updateStyles(property: 'font-size' | 'padding' | 'margin'): void {
    const selection = window.getSelection();
    const previewDiv = this.previewContainer.nativeElement;

    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const selectedElements = this.getSelectedElements(range);

      if (selectedElements.length > 0) {
        // Apply styles to selected elements and their containers
        selectedElements.forEach(element => {
          this.applyStyleToElementAndContainers(element, property);
        });
      }
    } else {
      // No selection - apply to all elements
      const elements = previewDiv.getElementsByTagName('*');
      for (let i = 0; i < elements.length; i++) {
        this.applyStyle(elements[i], property);
      }
    }

    // Update the HTML content to reflect changes
    this.htmlContent = previewDiv.innerHTML;
  }

  private getSelectedElements(range: Range): HTMLElement[] {
    const elements: HTMLElement[] = [];
    const container = range.commonAncestorContainer;

    if (container.nodeType === Node.TEXT_NODE) {
      if (container.parentElement) {
        elements.push(container.parentElement);
      }
    } else if (container.nodeType === Node.ELEMENT_NODE) {
      elements.push(container as HTMLElement);
    }

    // Add all elements within the range
    const walker = document.createTreeWalker(
      container,
      NodeFilter.SHOW_ELEMENT,
      {
        acceptNode: function (node) {
          return NodeFilter.FILTER_ACCEPT;
        }
      }
    );

    let node = walker.currentNode;
    while (node) {
      if (node.nodeType === Node.ELEMENT_NODE) {
        elements.push(node as HTMLElement);
      }
      node = walker.nextNode();
    }

    return elements;
  }

  private applyStyleToElementAndContainers(element: HTMLElement, property: 'font-size' | 'padding' | 'margin'): void {
    // Apply style to the element itself
    this.applyStyle(element, property);

    // Apply to parent elements until we reach the preview container
    let parent = element.parentElement;
    while (parent && parent !== this.previewContainer.nativeElement) {
      this.applyStyle(parent, property);
      parent = parent.parentElement;
    }
  }

  private applyStyle(element: HTMLElement, property: 'font-size' | 'padding' | 'margin'): void {
    switch (property) {
      case 'font-size':
        element.style.fontSize = `${this.fontSize}px`;
        break;
      case 'padding':
        element.style.padding = `${this.padding}px`;
        break;
      case 'margin':
        element.style.margin = `${this.margin}px`;
        break;
    }
  }

  public generatePreview(): void {
    this.previewHtml = this.sanitizer.bypassSecurityTrustHtml(this.htmlContent);
  }

  public generatePreviewFromData(): void {
    const previewContent = `
      <h1>${this.lessonData.title || 'Untitled Lesson'}</h1>
      <h2>Student Details</h2>
      <p><strong>Type:</strong> ${this.lessonData.studentType}</p>
      <p><strong>Level:</strong> ${this.lessonData.studentLevel}</p>
      <p><strong>Interests:</strong> ${this.lessonData.studentInterest}</p>
      <h2>Lesson Plan</h2>
      <p>${this.lessonData.plan}</p>
      <h2>Needs Approach</h2>
      <p>${this.lessonData.needsApproach}</p>
    `;
    this.previewHtml = this.sanitizer.bypassSecurityTrustHtml(previewContent);
  }

  public sendMessage(): void {
    if (!this.message.trim() && this.files.length === 0) return;

    this.conversation.push({ role: 'user', content: this.message, splitContent: this.splitContent(this.message) });
    this.scrollToBottom();

    const data = {
      messages: this.conversation,
      aiProvider: this.selectedChatbot?.aiProvider || '',
      model: this.selectedChatbot?.model || '',
      maxTokens: this.selectedChatbot?.maxTokens || 1000,
      maxMessages: this.selectedChatbot?.maxMessages || 5,
      systemMsg: 'You are a teaching assistant that creates lesson descriptions in clear, well-structured text format.',
      files: this.files,
      studentInfo: {
        level: this.lessonData.studentLevel || '',
        learningStyle: this.lessonData.learningStyle,
        type: this.lessonData.studentType || ''
      }
    };

    this.message = '';
    this.chatService.sendPromptToChosenAi(data).subscribe(
      (res) => {
        this.conversation.push(res.res);
        this.conversation.forEach((message: { role: string; content: string; splitContent?: any }) => {
          message.splitContent = this.splitContent(message.content);
        });
        this.message = '';
        this.files = [];
        this.scrollToBottom();
      },
      (error) => {
        console.error('Error:', error);
        this.snackBar.open('An error occurred while sending the message', 'Close', { duration: 5000 });
      }
    );
  }

  private splitContent(content: string): Array<{ type: 'text' | 'code'; value: any }> {
    content = content.replace(/```html/g, '').replace(/```/g, '').replace(/`/g, '');
    const parts: { type: 'text' | 'code', value: any }[] = [];
    parts.push({ type: 'text', value: content });
    parts.forEach(part => {
      if (part.type === 'text') {
        const safeHtml = this.sanitizer.bypassSecurityTrustHtml(this.converter.makeHtml(part.value));
        part.value = safeHtml;
      }
    });
    return parts;
  }

  private scrollToBottom(): void {
    try {
      setTimeout(() => {
        this.chatMessages.nativeElement.scrollTop = this.chatMessages.nativeElement.scrollHeight;
      }, 0);
    } catch (err) {
      console.error('Error scrolling to bottom:', err);
    }
  }

  public saveLesson(): void {
    const message = `Create a concise grammar lesson description based on the following information:
      Student Context:
      - Type: ${this.lessonData.studentType || ''}
      - Level: ${this.lessonData.studentLevel || ''}
      - Interests: ${this.lessonData.studentInterest}
      - Learning Style: ${this.lessonData.learningStyle}
      
      Lesson Details:
      - Topic: ${this.lessonData.plan}
      - Duration: ${this.lessonData.duration} minutes
      - Approach to Student Needs: ${this.lessonData.needsApproach}
      - Reason: ${this.lessonData.reason}`;

    const data = {
      messages: [{ role: 'user', content: message }],
      aiProvider: this.selectedChatbot?.aiProvider || '',
      model: this.selectedChatbot?.model || '',
      maxTokens: this.selectedChatbot?.maxTokens || 1000,
      maxMessages: this.selectedChatbot?.maxMessages || 5,
      systemMsg: 'You are a teaching assistant that creates clear, well-structured grammar lesson descriptions. Focus on explaining grammar concepts, exercises, and how they are tailored to student needs.',
      files: [],
      studentInfo: {
        level: this.lessonData.studentLevel || '',
        learningStyle: this.lessonData.learningStyle
      }
    };

    this.chatService.sendPromptToChosenAi(data).subscribe(
      (res) => {
        this.lessonData.description = res.res.content.replace(/```html/g, '').replace(/```/g, '').replace(/`/g, '');
        this.customLessonService.saveCustomLesson(this.lessonData);
        this.dialogRef.close(this.lessonData);
      },
      (error) => {
        console.error('Error:', error);
        this.snackBar.open('An error occurred while generating the lesson plan', 'Close', { duration: 5000 });
      }
    );
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public addActivity(): void {
    this.lessonData.activities.push({
      name: '',
      description: '',
      duration: 15
    });
  }

  public removeActivity(index: number): void {
    this.lessonData.activities.splice(index, 1);
  }

  public async improveInputs(): Promise<void> {
    try {
      const message = `
        Topic: ${this.lessonData.plan}
        Student Level: ${this.lessonData.studentLevel || ''}
        Student Type: ${this.lessonData.studentType || ''}`;

      const data = {
        messages: [{ role: 'user', content: message }],
        aiProvider: this.mentorChatbot?.aiProvider || '',
        model: this.mentorChatbot?.model || '',
        maxTokens: this.mentorChatbot?.maxTokens || 1000,
        maxMessages: this.mentorChatbot?.maxMessages || 5,
        systemMsg: this.mentorChatbot.systemMsg,
      };

      this.chatService.sendPromptToChosenAi(data).subscribe(
        (res) => {
          try {
            const content = res.res.content.trim();
            let expandedTopic = '';

            try {
              const response = JSON.parse(content);
              if (response.expandedTopic) {
                expandedTopic = response.expandedTopic;
              }
            } catch (jsonError) {
              const match = content.match(/"expandedTopic"\s*:\s*"([^"]*)"/);
              if (match && match[1]) {
                expandedTopic = match[1];
              } else {
                expandedTopic = content;
              }
            }

            if (expandedTopic) {
              this.lessonData.plan = expandedTopic;
              this.mentorResult = expandedTopic;
              this.snackBar.open('Temat został rozszerzony', 'OK', { duration: 3000 });
            } else {
              throw new Error('Nie znaleziono rozszerzonego tematu w odpowiedzi');
            }
          } catch (error) {
            console.error('Error processing AI response:', error);
            this.snackBar.open('Błąd podczas przetwarzania rozszerzonego tematu', 'OK', { duration: 5000 });
          }
        },
        (error) => {
          console.error('Error:', error);
          this.snackBar.open('Wystąpił błąd podczas ulepszania danych', 'OK', { duration: 5000 });
        }
      );
    } catch (error) {
      console.error('Error:', error);
      this.snackBar.open('Wystąpił błąd podczas ulepszania danych', 'OK', { duration: 5000 });
    }
  }

  public async generateLesson(): Promise<void> {
    this.selectedChatbot = this.chatbots.find(chatbot => chatbot.name === 'Daniel');
    try {
      const message = `Create a detailed grammar printable a4 format html in size 29cm x 21cm based on:
      
      Mentor's Suggestion: ${this.mentorResult}
      Student Interests: ${this.lessonData.studentInterest}`;

      const data = {
        messages: [{ role: 'user', content: message }],
        aiProvider: this.selectedChatbot?.aiProvider || '',
        model: this.selectedChatbot?.model || '',
        maxTokens: this.selectedChatbot?.maxTokens || 1000,
        maxMessages: this.selectedChatbot?.maxMessages || 5,
        systemMsg: this.selectedChatbot.systemMsg,
        files: []
      };

      this.chatService.sendPromptToChosenAi(data).subscribe(
        (res) => {
          this.htmlContent = res.res.content.replace(/```html/g, '').replace(/```/g, '').replace(/`/g, '');
          this.generatePreview();
        },
        (error) => {
          console.error('Error:', error);
          this.snackBar.open('An error occurred while generating the lesson', 'Close', { duration: 5000 });
        }
      );
    } catch (error) {
      console.error('Error:', error);
      this.snackBar.open('An error occurred while preparing the lesson', 'Close', { duration: 5000 });
    }
  }

  public printPreview(): void {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = this.htmlContent;
    print({
      printable: tempDiv,
      type: 'html',
      documentTitle: this.lessonData.title || 'Custom Lesson',
      targetStyles: ['*']
    });
  }
}
