import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import {
    CanvasWhiteboardComponent,

} from "ng2-canvas-whiteboard";

import { GroupsService } from "src/app/shared/services/groups.service";
import { Subscription } from "rxjs";
import { CreateReportComponent } from "../components/create-report/create-report.component";
import { CoursesPlanComponent } from "src/app/courses/courses-plan/courses-plan.component";
import { PiratesGameComponent } from "src/app/profile/teacher/classroom-games/pirates-game/pirates-game.component";
import { FinancesStatsComponent } from "src/app/profile/owner/finances-stats/finances-stats.component";
import { LessonsComponent } from '../../lessons/lessons.component';
import { PlatformGameTestComponent } from '../../live/boardgame/platform-game-test/platform-game-test.component';
import { QuizWriteCheckComponent } from '../../profile/student/quiz/quiz-write-check/quiz-write-check.component';
import { TestBedComponent } from './test-bed/test-bed.component';
import { LessonActivitiesAddEditComponent } from '../../profile/teacher/teacher-activities/lesson-activities-add-edit/lesson-activities-add-edit.component';
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { WorkHoursComponent } from '../../profile/teacher/work-hours/work-hours.component';
import { HelpersService } from "../services/helpers.service";
import { SetTextConstructorComponent } from "src/app/database/sets/set-text-constructor/set-text-constructor.component";
import { CreateFreesetItemsWithChatComponent } from "src/app/database/sets-free/create-freeset-items-with-chat/create-freeset-items-with-chat.component";
import { VideoWordSentencePictureComponent } from "src/app/video-maker/video-generator/videos-types/video-word-sentence-picture/video-word-sentence-picture.component";
import { GenerateAudioService } from "../services/database/generate-audio.service";
import { VideoIntroPicturesBackgroundComponent } from "src/app/video-maker/video-generator/video-intro-pictures-background/video-intro-pictures-background.component";
import { VideoDataCreatorComponent } from "src/app/video-maker/video-data-creator/video-data-creator.component";
import { FactVideoQuestionComponent } from "src/app/database/facts/fact-video-question/fact-video-question.component";
import { CustomLessonCreatorComponent } from "src/app/groups/groups-lessons/custom-lesson-creator/custom-lesson-creator.component";

@Component({
    selector: "app-simple-test",
    viewProviders: [CanvasWhiteboardComponent],

    templateUrl: "./simple-test.component.html",
    styleUrls: ["./simple-test.component.css"],
})
export class SimpleTestComponent {
    fact = {
        "_id": "66b31e64ff02bf5af747c262",
        "data": {
            "level": "B1",
            "imageTag": "A confused tourist holding a coin near their ear on a Hawaiian beach, with a stern-looking lifeguard watching suspiciously",
            "category": "Bizarre Laws From Around the World",
            "texts": {
                "English": "In Hawaii, it's illegal to put a coin in your ear. This odd law is still on the books in the Aloha State!"
            },
            "explanationOfTheAnswer": {
                "English": "This quirky law dates back to when con artists would pretend to pull coins from people's ears as a magic trick to scam tourists."
            },
            "image": "https://images-from-chat.fra1.digitaloceanspaces.com/images/A%20confused%20tourist%20holding%20a%20coin%20near%20their%20ear%20on%20a%20Hawaiian%20beach%2C%20with%20a%20stern-looking%20lifeguard%20watching%20suspiciously.png",
            "audio": "https://backend-int.linget.it/api/read/getaudiodata?url=freeAudio/1723014775733.wav",
            "created": 1723014757249,
            "_id": "66b31e64ff02bf5af747c262",
            "updated": 1723014778505,
            "explanationAudio": "https://backend-int.linget.it/api/read/getaudiodata?url=freeAudio/1723014776376.wav"
        },
        "__v": 0,
        "level": "B1",
        "imageTag": "A confused tourist holding a coin near their ear on a Hawaiian beach, with a stern-looking lifeguard watching suspiciously",
        "category": "Bizarre Laws From Around the World",
        "texts": {
            "English": "In Hawaii, it's illegal to put a coin in your ear. This odd law is still on the books in the Aloha State!",
            "German": "In Hawaii ist es illegal, eine Münze ins Ohr zu stecken. Dieses seltsame Gesetz steht im Aloha-Staat immer noch in den Büchern!",
            "Spanish": "En Hawái, es ilegal ponerse una moneda en la oreja. ¡Esta extraña ley todavía está vigente en el Estado Aloha!",
            "PortugueseBrazil": "No Havaí, é ilegal colocar uma moeda na orelha. Esta lei estranha ainda está em vigor no Estado Aloha!",
            "Turkish": "Hawaii'de kulağınıza bozuk para koymak yasadışıdır. Bu tuhaf yasa hala Aloha Eyaleti'nin kanunlarında yer alıyor!",
            "French": "À Hawaï, il est illégal de mettre une pièce dans son oreille. Cette loi étrange est toujours en vigueur dans l'État d'Aloha !",
            "Polish": "Na Hawajach wkładanie monety do ucha jest nielegalne. To dziwne prawo wciąż obowiązuje w stanie Aloha!",
            "Vietnamese": "Ở Hawaii, việc đặt đồng xu vào tai là bất hợp pháp. Luật kỳ lạ này vẫn còn hiệu lực ở Tiểu bang Aloha!",
            "Thai": "ในฮาวาย การใส่เหรียญเข้าไปในหูเป็นสิ่งผิดกฎหมาย กฎหมายแปลกประหลาดนี้ยังคงมีผลบังคับใช้ในรัฐอโลฮา!",
            "Indonesian": "Di Hawaii, memasukkan koin ke telinga adalah tindakan ilegal. Hukum aneh ini masih berlaku di Negara Bagian Aloha!",
            "Hindi": "हवाई में, अपने कान में सिक्का डालना गैरकानूनी है। यह अजीब कानून अभी भी अलोहा राज्य में प्रचलित है!"
        },
        "explanationOfTheAnswer": {
            "English": "This quirky law dates back to when con artists would pretend to pull coins from people's ears as a magic trick to scam tourists.",
            "German": "Dieses skurrile Gesetz geht auf die Zeit zurück, als Betrüger vorgaben, Münzen aus den Ohren von Menschen zu ziehen, als Zaubertrick, um Touristen zu betrügen.",
            "Spanish": "Esta ley peculiar se remonta a cuando los estafadores fingían sacar monedas de las orejas de las personas como un truco de magia para engañar a los turistas.",
            "PortugueseBrazil": "Essa lei peculiar remonta à época em que golpistas fingiam tirar moedas das orelhas das pessoas como um truque de mágica para enganar turistas.",
            "Turkish": "Bu tuhaf yasa, dolandırıcıların turistleri kandırmak için sihirli bir numara olarak insanların kulaklarından para çıkarıyormuş gibi yaptıkları zamanlara dayanıyor.",
            "French": "Cette loi étrange remonte à l'époque où des escrocs prétendaient sortir des pièces des oreilles des gens comme un tour de magie pour arnaquer les touristes.",
            "Polish": "To osobliwe prawo sięga czasów, gdy oszuści udawali, że wyciągają monety z uszu ludzi jako sztuczkę magiczną, aby oszukiwać turystów.",
            "Vietnamese": "Luật kỳ quặc này có từ thời các kẻ lừa đảo giả vờ lấy tiền xu từ tai người khác như một trò ảo thuật để lừa gạt du khách.",
            "Thai": "กฎหมายแปลกประหลาดนี้มีมาตั้งแต่สมัยที่พวกต้มตุ๋นแกล้งทำเป็นดึงเหรียญออกจากหูของผู้คนเป็นมายากลเพื่อหลอกนักท่องเที่ยว",
            "Indonesian": "Hukum aneh ini berasal dari masa ketika penipu berpura-pura mengeluarkan koin dari telinga orang sebagai trik sulap untuk menipu wisatawan.",
            "Hindi": "यह विचित्र कानून उस समय से चला आ रहा है जब धोखेबाज पर्यटकों को ठगने के लिए जादू के एक प्रदर्शन के रूप में लोगों के कानों से सिक्के निकालने का दिखावा करते थे।"
        },
        "image": "https://images-from-chat.fra1.digitaloceanspaces.com/images/A%20confused%20tourist%20holding%20a%20coin%20near%20their%20ear%20on%20a%20Hawaiian%20beach%2C%20with%20a%20stern-looking%20lifeguard%20watching%20suspiciously.png",
        "audio": "https://backend-int.linget.it/api/read/getaudiodata?url=freeAudio/1724147548065.wav",
        "created": 1723014757249,
        "updated": 1724147579092,
        "explanationAudio": "https://backend-int.linget.it/api/read/getaudiodata?url=freeAudio/1724147550547.wav",
        "video": "https://fra1.digitaloceanspaces.com/videorepo/cinematic/1731546_Paradise_Idyllic_1920x1080.mp4",
        "shortVideo": null,
        "shortCreated": 1723016210657,
        "ABCAnswers": {
            "English": [
                "Put it in your ear",
                "Flip it on the beach",
                "Use it as a surfboard wax"
            ]
        },
        "ABCcorrectAnswer": {
            "English": "Put it in your ear"
        },
        "ABCQuestionWithAnswers": {
            "English": "What strange thing is illegal to do with a coin in Hawaii?\nA) Put it in your ear\nB) Flip it on the beach\nC) Use it as a surfboard wax",
            "German": "Welche seltsame Sache ist es in Hawaii illegal, mit einer Münze zu tun?\nA) Sie ins Ohr stecken\nB) Sie am Strand werfen\nC) Sie als Surfbrettwachs verwenden",
            "Spanish": "¿Qué cosa extraña es ilegal hacer con una moneda en Hawái?\nA) Ponerla en tu oreja\nB) Lanzarla al aire en la playa\nC) Usarla como cera para tabla de surf",
            "PortugueseBrazil": "Que coisa estranha é ilegal fazer com uma moeda no Havaí?\nA) Colocá-la na orelha\nB) Jogá-la para o alto na praia\nC) Usá-la como cera para prancha de surf",
            "Turkish": "Hawaii'de bir madeni parayla yapmak yasak olan tuhaf şey nedir?\nA) Kulağınıza koymak\nB) Plajda yazı tura atmak\nC) Sörf tahtası cilası olarak kullanmak",
            "French": "Quelle chose étrange est-il illégal de faire avec une pièce de monnaie à Hawaï ?\nA) La mettre dans son oreille\nB) La lancer en l'air sur la plage\nC) L'utiliser comme cire pour planche de surf",
            "Polish": "Jaka dziwna rzecz jest nielegalna do zrobienia z monetą na Hawajach?\nA) Włożenie jej do ucha\nB) Podrzucanie jej na plaży\nC) Użycie jej jako wosku do deski surfingowej",
            "Vietnamese": "Điều kỳ lạ nào là bất hợp pháp khi làm với đồng xu ở Hawaii?\nA) Đặt nó vào tai\nB) Tung nó trên bãi biển\nC) Sử dụng nó làm sáp cho ván lướt sóng",
            "Thai": "สิ่งแปลกอะไรที่ผิดกฎหมายที่จะทำกับเหรียญในฮาวาย?\nA) ใส่มันในหู\nB) โยนเหรียญบนชายหาด\nC) ใช้มันเป็นแวกซ์สำหรับกระดานโต้คลื่น",
            "Indonesian": "Hal aneh apa yang ilegal dilakukan dengan koin di Hawaii?\nA) Memasukkannya ke telinga\nB) Melemparnya di pantai\nC) Menggunakannya sebagai lilin papan selancar",
            "Hindi": "हवाई में सिक्के के साथ कौन सी अजीब चीज करना गैरकानूनी है?\nA) इसे अपने कान में डालना\nB) समुद्र तट पर इसे उछालना\nC) इसे सर्फबोर्ड वैक्स के रूप में इस्तेमाल करना"
        },
        "ABCExplanation": {
            "English": "A) Put it in your ear. This odd law came from stopping con artists who tricked tourists with fake magic coin tricks.",
            "German": "A) Steck es in dein Ohr. Dieses seltsame Gesetz entstand, um Betrüger zu stoppen, die Touristen mit gefälschten magischen Münztricks reinlegten.",
            "Spanish": "A) Póntelo en la oreja. Esta extraña ley surgió para detener a estafadores que engañaban a turistas con trucos falsos de monedas mágicas.",
            "PortugueseBrazil": "A) Coloque-o na sua orelha. Essa lei estranha surgiu para impedir golpistas que enganavam turistas com truques falsos de moedas mágicas.",
            "Turkish": "A) Kulağına koy. Bu tuhaf yasa, turistleri sahte sihirli para numaralarıyla kandıran dolandırıcıları durdurmak için ortaya çıktı.",
            "French": "A) Mettez-le dans votre oreille. Cette loi étrange est née pour arrêter les escrocs qui trompaient les touristes avec de faux tours de pièces magiques.",
            "Polish": "A) Włóż to do ucha. To dziwne prawo powstało, aby powstrzymać oszustów, którzy oszukiwali turystów fałszywymi sztuczkami z magicznymi monetami.",
            "Vietnamese": "A) Đặt nó vào tai của bạn. Luật kỳ lạ này xuất phát từ việc ngăn chặn những kẻ lừa đảo đánh lừa du khách bằng các trò ảo thuật tiền xu giả.",
            "Thai": "A) ใส่มันในหูของคุณ กฎหมายแปลกนี้เกิดขึ้นเพื่อหยุดพวกต้มตุ๋นที่หลอกลวงนักท่องเที่ยวด้วยกลเหรียญมายากลปลอม",
            "Indonesian": "A) Masukkan ke telinga Anda. Hukum aneh ini berasal dari upaya menghentikan penipu yang menipu wisatawan dengan trik koin ajaib palsu.",
            "Hindi": "A) इसे अपने कान में डालें। यह अजीब कानून पर्यटकों को नकली जादुई सिक्के के ट्रिक से धोखा देने वाले ठगों को रोकने के लिए आया था।"
        },
        "selected": true,
        "ABCQuestionWithAnswersAudio": "https://backend-int.linget.it/api/read/getaudiodata?url=freeAudio/1724147555829.wav",
        "ABCExplanationAudio": "https://backend-int.linget.it/api/read/getaudiodata?url=freeAudio/1724147557962.wav"

    }

    constructor(
        private helpers: HelpersService,
        private bottomSheet: MatBottomSheet,
        private dialog: MatDialog,
        private spinnerService: NgxSpinnerService,
        private groupsService: GroupsService,
        private generateAudioService: GenerateAudioService
    ) {

    }
    ngOnInit() {
        this.spinnerService.hide();
        this.openBottomSheet();




    }




    openBottomSheet() {
        this.dialog.open(CustomLessonCreatorComponent, { width: '100%', height: '100%', minHeight: '100%', minWidth: '100%', panelClass: 'full-screen', data: { fact: this.fact } });
    }

    ngOnDestroy() {
    }
}
